import { template as template_8dc60e3c14674eba87a2a873b62fc64d } from "@ember/template-compiler";
const FKControlMenuContainer = template_8dc60e3c14674eba87a2a873b62fc64d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
