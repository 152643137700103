import { template as template_df6b61676e364be9b37aae1a1263a1bb } from "@ember/template-compiler";
const FKLabel = template_df6b61676e364be9b37aae1a1263a1bb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
