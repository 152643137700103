import { template as template_231bf7908db7418cb079fb484a3bce97 } from "@ember/template-compiler";
const WelcomeHeader = template_231bf7908db7418cb079fb484a3bce97(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
